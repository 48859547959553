import React, { useState, createContext, useContext, useEffect } from "react";
import { WebSocketContext } from "./WebSocketProvider";
import { EventType, GameState } from "dall-escapades-common";
import { Outlet } from "react-router-dom";

export const GameStateContext = createContext<{
  gameState: GameState | null;
  setGameState: React.Dispatch<React.SetStateAction<GameState | null>> | null;
  playerId: string | null;
}>({ gameState: null, setGameState: null, playerId: null });

export default function GameStateProvider() {
  const [gameState, setGameState] = useState<GameState | null>(null);
  const [playerId, setPlayerId] = useState<string | null>(null);
  const socket = useContext(WebSocketContext);

  const eventHandler = (state: GameState) => {
    setGameState(state);
    if (state.players[socket.id]) {
      setPlayerId(socket.id);
    } else {
      setPlayerId(null);
    }
  };

  useEffect(() => {
    if (!socket) {
      console.log(`Socket is closed. Socket: ${socket}`);
    } else {
      console.log("Adding state listener");
      socket.on(EventType.STATE_UPDATE, eventHandler);
    }
    // Remove when it unmounts
    return () => {
      console.log("Removing state listener");
      socket?.off(EventType.STATE_UPDATE, eventHandler);
    };

    // Sometimes the handler function gets redefined
    // when the component using this hook updates (or rerenders)
    // So adding a dependency makes sure the handler is
    // up to date!
    // I can avoid this by making sure eventHandlers are constant!
  }, [socket]);

  return (
    <GameStateContext.Provider value={{ gameState, setGameState, playerId }}>
      <Outlet />
    </GameStateContext.Provider>
  );
}
