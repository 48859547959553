import { io } from "socket.io-client";
import { config } from "./config";

// https://socket.io/how-to/use-with-react

// "undefined" means the URL will be computed from the `window.location` object
export const socket = io(config.API_URL, {
  extraHeaders: {},
  autoConnect: false,
});
