import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GameStateContext } from "../../../../GameStateProvider";
import { useWebSocketSend } from "../../../../../hooks/useWebSocketSend";
import { EventType, Phase } from "dall-escapades-common";
import { WebSocketContext } from "../../../../WebSocketProvider";
import { navigateOnStateChange } from "../../../../../store/navigation";
import { Button } from "../../../../../lib";
import { Stack } from "@mui/material";

export const ParticipantLobbyPage = () => {
  const navigate = useNavigate();
  const { gameState } = useContext(GameStateContext);
  const socket = useContext(WebSocketContext);
  const send = useWebSocketSend();

  useEffect(() => {
    navigateOnStateChange(gameState, Phase.LOBBY, navigate);
  }, [gameState, navigate]);

  const handleStart = () => {
    send({ type: EventType.START });
  };

  const leaderStartDisplay =
    socket.id === gameState?.leader ? (
      <>
        <h2>you're the leader!</h2>
        <Button onClick={handleStart}>start!</Button>
      </>
    ) : (
      <></>
    );

  return (
    <Stack spacing={2} alignItems="center">
      <h1>waiting for game to start</h1>
      {leaderStartDisplay}
    </Stack>
  );
};
