import { useContext } from "react";
import { WebSocketContext } from "../components/WebSocketProvider";
import { ClientEvent, EventType } from "dall-escapades-common";

export function useWebSocketSend() {
  const socket = useContext(WebSocketContext);

  // let EventData contain eventType and so send takes this as input
  const send = (event: ClientEvent) => {
    if (!socket) {
      throw Error(`Socket is closed. Socket: ${socket}`);
    }
    socket.emit<EventType>(event.type, event.data);
    console.log(`[${event.type}] sent: ${JSON.stringify(event.data)}`);
  };
  return send;
}
