import { Box, CircularProgress, Stack, Typography } from "@mui/material";

export function SubmissionDisplay(input: {
  src?: string;
  prompt?: string;
  onClick?: () => void;
  sx?: React.CSSProperties;
}) {
  return input.src ? (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        position: "relative",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            position: "relative",
          }}
        >
          <img
            src={input.src}
            alt={input.prompt ?? "Could not get prompt"}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "4px",
              objectFit: "contain",
            }}
          />
        </Box>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          {input.prompt ?? "Could not get prompt"}
        </Typography>
      </Stack>
    </Box>
  ) : (
    <CircularProgress />
  );
}
