export const renderer = ({
  hours,
  minutes,
  seconds,
  completed,
}: {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  if (completed) {
    return <span>time's up!</span>;
  } else {
    return <span>{3600 * hours + 60 * minutes + seconds}</span>;
  }
};
