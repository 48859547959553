import { Phase } from "dall-escapades-common";
import { useNavigate } from "react-router-dom";
import { navigateOnStateChange } from "../../../../../store/navigation";
import { useContext, useEffect } from "react";
import { GameStateContext } from "../../../../GameStateProvider";
import { Stack, Typography } from "@mui/material";
import { SubmissionDisplay } from "../../../../../lib/SubmissionDisplay";

export const ParticipantSubmissionsPage = () => {
  const { gameState } = useContext(GameStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    navigateOnStateChange(gameState, Phase.SUBMISSIONS, navigate);
  }, [gameState]);

  const player = gameState?.showSubmissionOf
    ? gameState?.players[gameState.showSubmissionOf] ?? null
    : null;

  return (
    <Stack alignItems="center" sx={{ width: "100%", height: "100%" }}>
      <Typography variant="h3">submissions!</Typography>
      <SubmissionDisplay src={player?.submission} prompt={player?.prompt} />
    </Stack>
  );
};
