import { TextField, InputAdornment } from "@mui/material";
import { ChangeEventHandler } from "react";
import { Button } from "./Button";

export function TextInput(input: {
  id: string;
  label: string;
  value: string;
  buttonText: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  multiline?: boolean;
  fullWidth?: boolean;
  errorText?: string | null;
}) {
  return (
    <TextField
      multiline={input.multiline ?? false}
      fullWidth={input.fullWidth ?? false}
      id={input.id}
      label={input.label}
      value={input.value}
      onChange={input.onChange}
      error={!!input.errorText}
      helperText={input.errorText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={input.onClick}>{input.buttonText}</Button>
            {/* <IconButton
              aria-label="send"
              edge="end"
              onClick={input.onClick}
              type="submit"
            >
              <SendIcon />
            </IconButton> */}
          </InputAdornment>
        ),
      }}
    />
  );
}
