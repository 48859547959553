import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GameStateContext } from "../../../../GameStateProvider";
import { Phase, Player } from "dall-escapades-common";
import { navigateOnStateChange } from "../../../../../store/navigation";
import { Box, List, ListItem, Paper, Stack, Typography } from "@mui/material";

export const HostLobbyPage = () => {
  const { gameState } = useContext(GameStateContext);
  const navigate = useNavigate();

  const formatPlayers = (players: Player[]) => {
    return players.map((player: Player) => (
      <ListItem key={player.name}>
        <Paper>
          <Box p={2}>{player.name}</Box>
        </Paper>
      </ListItem>
    ));
  };

  useEffect(() => {
    navigateOnStateChange(gameState, Phase.LOBBY, navigate);
  }, [gameState]);

  if (!gameState?.code) return null;

  return (
    <Stack spacing={2} alignItems="center">
      <Typography variant="h3" align="center">
        host!
      </Typography>
      <Typography variant="body1" align="center">
        code: {gameState?.code ?? "not found"}
      </Typography>
      <Typography variant="body1">players joined:</Typography>
      <List>{formatPlayers(Object.values(gameState?.players ?? {}))}</List>
    </Stack>
  );
};
