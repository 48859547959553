import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthCtx } from "../AuthContextProvider";

export const GuardedRoutes = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthCtx);

  useEffect(() => {
    if (!user) navigate("/");
  }, [user]);

  return <Outlet />;
};
