import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#9B4D23", // Slightly pinker SaddleBrown for a rich wood color
    },
    secondary: {
      main: "#E2B89C", // Slightly pinker Tan for a lighter wood accent
    },
    background: {
      default: "#F4E0D0", // Light beige with a hint of pink for a warm gallery feel
      paper: "#FAE0D7", // AntiqueWhite with a touch of pink for a slightly textured look
    },
    text: {
      primary: "#3E2723", // Dark brown for readable text (unchanged)
      secondary: "#5D4037", // Slightly lighter brown for secondary text (unchanged)
    },
    action: {
      active: "#B1988F", // Light brown with a hint of pink for interactive elements
    },
    error: {
      main: "#D32F2F", // Standard error color for consistency
    },
    warning: {
      main: "#FFA000", // Standard warning color for consistency
    },
    info: {
      main: "#1976D2", // Standard info color for consistency
    },
    success: {
      main: "#388E3C", // Standard success color for consistency
    },
  },
});
