import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthCtx } from "../AuthContextProvider";
import { GameStateContext } from "../GameStateProvider";
import {
  Box,
  Container,
  Paper,
  Typography,
  AppBar,
  Toolbar,
} from "@mui/material";
import { Button } from "../../lib/Button";
import { TextInput } from "../../lib";
import { signInWithRedirect, signOut } from "aws-amplify/auth";
import { config } from "../../config";

const Navigation = () => {
  const [stateField, setStateField] = useState<string>("");
  const { user } = useContext(AuthCtx);
  const { gameState, setGameState } = useContext(GameStateContext);
  const navigate = useNavigate();

  const home = () => {
    navigate("/");
  };

  const setGameStateFn = () => {
    if (setGameState) setGameState(JSON.parse(stateField));
  };

  useEffect(() => {
    setStateField(JSON.stringify(gameState, null, 2));
  }, [gameState]);

  return (
    <>
      <Paper sx={{ height: "100%" }}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AppBar
            position="static"
            color="secondary"
            elevation={0}
            sx={{ flexGrow: 0 }}
          >
            <Toolbar>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {user?.email ? `Signed in as ${user?.email}` : `Not signed in`}
              </Typography>
              <Button onClick={home} sx={{ mr: 1 }}>
                home
              </Button>
              {user ? (
                <Button onClick={() => signOut()}>Log Out</Button>
              ) : (
                <Button onClick={() => signInWithRedirect()} sx={{ mr: 1 }}>
                  Sign In
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <Box sx={{ flexGrow: 1, maxHeight: "100%", overflow: "auto" }}>
            <Box sx={{ height: "100%" }}>
              <Outlet />
            </Box>
          </Box>
          <Container maxWidth="lg" sx={{ flexGrow: 0 }}>
            <Typography variant="caption">{config.STAGE}</Typography>
          </Container>
        </Box>
      </Paper>
      {config.DEBUG && (
        <TextInput
          id="setState"
          label="state"
          value={stateField}
          buttonText="update!"
          multiline={true}
          fullWidth={true}
          onChange={(e) => setStateField(e.target.value)}
          onClick={setGameStateFn}
        ></TextInput>
      )}
    </>
  );
};

export default Navigation;
