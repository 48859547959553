import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GameStateContext } from "../../../../GameStateProvider";
import { useWebSocketSend } from "../../../../../hooks/useWebSocketSend";
import { EventType } from "dall-escapades-common";
import { TextInput } from "../../../../../lib";

export const ParticipantPromptPage = () => {
  const { gameState } = useContext(GameStateContext);
  const [prompt, setPrompt] = useState<string>("");
  const [promptSent, setPromptSent] = useState<boolean>(false);
  const navigate = useNavigate();
  const send = useWebSocketSend();

  useEffect(() => {
    if (gameState && gameState?.phase !== "prompt") {
      navigate(`./../${gameState?.phase}`, { relative: "path" });
    }
  }, [gameState]);

  if (!gameState) throw Error("no game state");

  const sendPrompt = () => {
    send({ type: EventType.PROMPT, data: { prompt } });
    setPromptSent(true);
  };

  return (
    <div>
      <h1>play!</h1>
      <p>write a prompt</p>
      <p>theme: {gameState?.theme}</p>
      {!promptSent ? (
        <TextInput
          label="Prompt"
          id="prompt-text-input"
          buttonText="send!"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          onClick={sendPrompt}
        />
      ) : (
        <p>Your prompt has been sent!</p>
      )}
    </div>
  );
};
