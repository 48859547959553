import { useContext, useEffect } from "react";
import { GameStateContext } from "../../../../GameStateProvider";
import { useNavigate } from "react-router-dom";
import { Phase } from "dall-escapades-common";
import { navigateOnStateChange } from "../../../../../store/navigation";
import Countdown from "react-countdown";
import { renderer } from "../../../../../store/countdown";
import { Stack, Typography } from "@mui/material";
import { AllSubmissions } from "../../../../../lib/AllSubmissions";

export const HostVotePage = () => {
  const { gameState } = useContext(GameStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    navigateOnStateChange(gameState, Phase.VOTE, navigate);
  }, [gameState]);

  return (
    <Stack
      alignItems="center"
      sx={{
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h3">vote!</Typography>
      {gameState && <AllSubmissions gameState={gameState} />}
      <Countdown date={gameState?.deadline} renderer={renderer} />
    </Stack>
  );
};
