import { useContext, useEffect } from "react";
import { GameStateContext } from "../../../../GameStateProvider";
import { useNavigate } from "react-router-dom";
import { useWebSocketSend } from "../../../../../hooks/useWebSocketSend";
import { EventType, Phase } from "dall-escapades-common";
import { navigateOnStateChange } from "../../../../../store/navigation";
import { Stack, Typography } from "@mui/material";
import { AllSubmissions } from "../../../../../lib/AllSubmissions";
import Countdown from "react-countdown";
import { renderer } from "../../../../../store/countdown";

export const ParticipantVotePage = () => {
  const { gameState } = useContext(GameStateContext);
  const send = useWebSocketSend();
  const navigate = useNavigate();

  const handleVote = (playerId: string) => {
    send({ type: EventType.VOTE, data: { playerId } });
  };

  useEffect(() => {
    navigateOnStateChange(gameState, Phase.VOTE, navigate);
  }, [gameState]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Typography variant="h3">vote!</Typography>
      {gameState && (
        <AllSubmissions gameState={gameState} onClick={handleVote} />
      )}
      <Countdown date={gameState?.deadline} renderer={renderer} />
    </Stack>
  );
};
