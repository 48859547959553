import React, { useEffect, createContext } from "react";
import { Socket } from "socket.io-client";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { socket } from "../socket";
import { fetchAuthSession } from "aws-amplify/auth";

export const WebSocketContext = createContext<Socket>(socket);

async function connect(options: { joinCode?: string }) {
  const session = await fetchAuthSession();
  const token = session?.tokens?.accessToken;
  console.log(token);

  if (!socket.io.opts.extraHeaders)
    throw new Error("socket.io.opts.extraHeaders is undefined");
  if (token) socket.io.opts.extraHeaders.Authorization = `Bearer ${token}`;
  if (options.joinCode) socket.io.opts.extraHeaders.joinCode = options.joinCode;

  socket.connect();
}

export default function WebSocketProvider() {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    socket.on("connect", () => {
      if (socket.recovered) {
        // any event missed during the disconnection period will be received now
        console.log("reconnected");
        console.log("receiving missed events...");
      } else {
        // new or unrecoverable session
        console.log("connected with new session ", socket.id);
      }
    });

    socket.on("disconnect", () => {
      console.log("disconnected from server");
      navigate("/");
    });

    connect({ joinCode: code });

    // Cleanup function to close the websocket connection when leaving the context
    return () => {
      socket.disconnect();
      console.log("WebSocket connection closed");
    };
  }, [code]);

  return (
    <WebSocketContext.Provider value={socket}>
      <Outlet />
    </WebSocketContext.Provider>
  );
}
