/* ui/src/store/auth/AuthContextProvider.tsx */

// Global imports
import { createContext, useEffect, useMemo, useState } from "react";

// Project dependencies
import {
  fetchUserAttributes,
  FetchUserAttributesOutput,
  getCurrentUser,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";

export type UserAttributes = Pick<FetchUserAttributesOutput, "sub" | "email">;

export interface AuthContext {
  user?: UserAttributes;
  error?: unknown;
  customState?: string | null;
}

// Auth context
export const AuthCtx = createContext<AuthContext>({});

export const AuthContextProvider = (props: {
  children: React.ReactElement;
}): React.ReactElement => {
  const [user, setUser] = useState<UserAttributes | undefined>();
  const [error, setError] = useState<unknown | null>(null);
  const [customState, setCustomState] = useState<string | null>(null);

  // Check if user detail is persisted, mostly catering for refreshing of the browser
  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }): void => {
      switch (payload.event) {
        case "signInWithRedirect":
          console.log("signInWithRedirect event", payload);
          getUser();
          break;
        case "signInWithRedirect_failure":
          setError("An error has occurred during the OAuth flow.");
          break;
        case "customOAuthState":
          setCustomState(payload.data); // this is the customState provided on signInWithRedirect function
          break;
      }
    });

    getUser();

    return unsubscribe;
  }, []);

  const getUser = async (): Promise<void> => {
    try {
      await getCurrentUser();
      const userAttributes: UserAttributes = await fetchUserAttributes();
      setUser(userAttributes);
    } catch (e) {
      console.error("Failed to get user: ", e);
      setUser(undefined);
    }
  };

  const auth: AuthContext = useMemo(
    () => ({
      user,
      error,
      customState,
    }),
    [user, error, customState]
  );
  return <AuthCtx.Provider value={auth}>{props.children}</AuthCtx.Provider>;
};
