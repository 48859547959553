import { Button as MuiButton } from "@mui/material";
import React from "react";

// Extend the input type to include all MuiButton properties plus any additional ones
type ButtonProps = React.ComponentProps<typeof MuiButton> & {
  children: React.ReactNode;
};

export function Button(props: ButtonProps) {
  return (
    <MuiButton variant="contained" {...props}>
      {props.children}
    </MuiButton>
  );
}
