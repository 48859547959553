export const config = {
  STAGE: process.env.REACT_APP_STAGE as string,
  COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
  COGNITO_USER_POOL_CLIENT_ID: process.env
    .REACT_APP_COGNITO_USER_POOL_CLIENT_ID as string,
  COGNITO_OAUTH_DOMAIN: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN as string,
  COGNITO_REDIRECT_SIGN_IN: process.env
    .REACT_APP_COGNITO_REDIRECT_SIGN_IN as string,
  COGNITO_REDIRECT_SIGN_OUT: process.env
    .REACT_APP_COGNITO_REDIRECT_SIGN_OUT as string,
  API_URL: process.env.REACT_APP_API_URL as string,
  DEBUG: process.env.REACT_APP_DEBUG === "true",
};
