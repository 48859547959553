import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GameStateContext } from "../../../../GameStateProvider";
import Countdown from "react-countdown";
import { renderer } from "../../../../../store/countdown";
import { navigateOnStateChange } from "../../../../../store/navigation";
import { Phase } from "dall-escapades-common";

export const HostPromptPage = () => {
  const { gameState } = useContext(GameStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    navigateOnStateChange(gameState, Phase.PROMPT, navigate);
  }, [gameState]);

  return (
    <div>
      <h1>topic!</h1>
      <p>{gameState?.theme}</p>
      <h2>deadline in:</h2>
      <Countdown date={gameState?.deadline} renderer={renderer} />
    </div>
  );
};
